angular.module('appApp')
  .controller('IngredientsListCtrl', function ($scope, $state, $stateParams, $http, Auth, $rootScope) {
    $scope.vars = {
      isAdmin: Auth.getCurrentUser().role === 'admin',
      product: $rootScope.product,
      ingredientsUrl: '/api/alimenti/query',
      filtered: $stateParams.filtered === 'true',
      pageSize: $stateParams.pageSize || 10,
      pageNumber: $stateParams.pageNumber || 1,
      tableConfig: {
        options: {
          url: '/api/alimenti/query' + ($stateParams.filtered === 'true' ? '/' + Auth.getCurrentUser().email : ''),
          idField: 'id',
          search: true,
          filterControl: true,
          // filterShowClear: true,
          maintainSelected: true,
          pagination: true,
          sidePagination: 'server',
          checkboxHeader: false,
          toolbar: '#toolbar',
          columns: [{
            field: 'id',
            title: 'Id',
            valign: 'right',
            sortable: true,
            filterControl: 'input',
            formatter: value => {
              const label = value.toString();
              return label.length < 1000000000 ?
                '0'.repeat(10 - label.length) + label : label;
            }
          }, {
            field: 'titolo',
            title: 'Ingrediente',
            align: 'left',
            valign: 'middle',
            width: '100%',
            sortable: true,
            filterControl: 'input',
            formatter: (value, row) => row.titolo || row.titoloI
          }, {
            field: 'tipo',
            title: 'Tipologia',
            align: 'left',
            valign: 'middle',
            sortable: true,
            filterControl: 'select',
            filterData: 'json:{"Ingrediente":"Ingrediente","Semilavorato":"Semilavorato","Additivo":"Additivo","Aroma":"Aroma"}',
            formatter: (value, row) => value || 'Ingrediente'
          }, {
            field: 'source',
            title: 'Fonte',
            align: 'left',
            valign: 'middle',
            sortable: true,
            filterControl: 'select',
            filterData: 'json:{"INRAN":"INRAN","USAV":"USAV","SCHEDA":"Scheda tecnica"}',
            formatter: (value, row) => {
              if(value) {
                if(value.indexOf('INRAN') >= 0) { return 'INRAN'; }
                else if(value.indexOf('USAV') >= 0) { return 'USAV'; }
                else if(value.indexOf('Scheda tecnica') >= 0) { return 'Scheda tecnica'; }
                else { return value; }
              } else {
                return row.id < 1000 ? 'INRAN' : 'Scheda tecnica';
              }
            }
          }, {
            field: 'produttore',
            title: 'Produttore',
            align: 'left',
            valign: 'middle',
            sortable: true,
            filterControl: 'input'
          }, {
            field: 'categoria',
            title: 'Categoria',
            align: 'left',
            valign: 'middle',
            sortable: true,
            filterControl: 'input',
            formatter: (value, row) => row.categoria || row.categoriaI
          }, {
            field: 'multilingual',
            title: 'Multilingua',
            valign: 'middle',
            sortable: true,
            filterControl: 'select',
            filterData: 'json:{"true":"Sì","false":"No"}',
            formatter: value => value === 'true' ? 'Sì' : 'No'
          }, {
            field: 'fav',
            title: 'Preferiti',
            align: 'center',
            valign: 'middle',
            checkbox: true,
            filterControl: 'select',
            filterData: 'json:{"' + Auth.getCurrentUser()._id + '":"Mostra solo preferiti"}',
            formatter: ($el, value) => {
              return !!$scope.vars.userSettings.favoriteIngredients
                .find(e => e === value.id);
            }
          }, {
            field: 'actions',
            title: '',
            align: 'center',
            valign: 'middle',
            events: {
              'click .continue': function (e, value, row) {
                $state.go('submitIngredient', {
                  ingredient: row._id,
                  pageNumber: $scope.vars.pageNumber,
                  pageSize: $scope.vars.pageSize,
                  filtered: $scope.vars.filtered
                });
              }
            },
            formatter: () => {
              return [
                '<a class="continue" href="javascript:void(0)" title="Vai al ingrediente">',
                '<i class="glyphicon glyphicon-chevron-right"></i>',
                '</a>  ',
              ].join('');
            }
          }]
        }
      }
    };

    $http.get('/api/user-settings/' + Auth.getCurrentUser()._id).then(res => {
      $scope.vars.userSettings = res.data;
      $scope.vars.userSettings.favoriteIngredients =
        $scope.vars.userSettings.favoriteIngredients || [];
    }).catch(err => {
      console.log(err);
      $scope.vars.userSettings = {
        _id: Auth.getCurrentUser()._id,
        link: '',
        logo: {
          id: 'logo-img',
          label: 'Imagine logo',
          value: '',
          userId: Auth.getCurrentUser()._id,
          preview: true
        },
        noExternalIngredientsInSearch: false,
        allergenStyle: 'bold',
        eWeightSymbol: false,
        colNutritionalVals: false,
        manufacturer: '',
        packager: '',
        manufacturingPlant: '',
        countryOfOrigin: '',
        favoriteIngredients: [],
        new: true
      };
    });

    $(function() {
      var $table = $('#table');

      $table.on('check.bs.table', function($el, row) {
        if(!$scope.vars.userSettings.favoriteIngredients.includes(row.id)) {
          $scope.vars.userSettings.favoriteIngredients.push(row.id);
          $scope.saveUserSettings();
        }
      });

      $table.on('uncheck.bs.table', function($el, row) {
        $scope.vars.userSettings.favoriteIngredients =
          $scope.vars.userSettings.favoriteIngredients.filter(e => e !== row.id);
        $scope.saveUserSettings();
      });

      $table.on('page-change.bs.table', function($el, number, size) {
        $scope.vars.pageNumber = number;
        $scope.vars.pageSize = size;
      });

      if($stateParams.pageSize || $stateParams.pageNumber) {
        $table.bootstrapTable('refreshOptions', {
          pageSize: parseInt($stateParams.pageSize || '10'),
          pageNumber: parseInt($stateParams.pageNumber || '1')
        });
      }

      $table.on('column-search.bs.table', function() {
        $('.bootstrap-table .search input').val('');
        // const searchbar = $('.bootstrap-table .search input');
        // const search = searchbar.val();
        //
        // if(search) {
        //   searchbar.val(search);
        // }
      });
    });

    $scope.saveUserSettings = () => {
      if($scope.vars.userSettings.new) {
        $http.post('/api/user-settings/', $scope.vars.userSettings).then(() => {
          return $http.get('/api/user-settings/' + Auth.getCurrentUser()._id);
        }).then(res => {
          $scope.vars.userSettings = res.data;
        }).catch(console.log);
      } else {
        $http.put('/api/user-settings/' + Auth.getCurrentUser()._id, $scope.vars.userSettings).then(() => {
          return $http.get('/api/user-settings/' + Auth.getCurrentUser()._id);
        }).then(res => {
          $scope.vars.userSettings = res.data;
        }).catch(console.log);
      }
    };

    $scope.toggleFilter = () => {
      $scope.vars.tableConfig.options.url = $scope.vars.ingredientsUrl +
        ($scope.vars.filtered ? '/' + Auth.getCurrentUser().email : '');
    };

    $scope.addIngredient = (multilingual) => $state.go('submitIngredient', {
      pageNumber: $scope.vars.pageNumber,
      pageSize: $scope.vars.pageSize,
      filtered: $scope.vars.filtered,
      multilingual: multilingual
    });

    $scope.gotoProduct = () => $state.go('product', {
      user: $scope.vars.product.user,
      id: $scope.vars.product.id
    });
  });
